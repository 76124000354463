import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  Backdrop,
  Button,
  CircularProgress,
  // FormControl,
  // Select,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik } from "formik";
import DraggablePrizeCard from "../../card/draggablePrizeCard";
import * as Yup from "yup";
import RefreshTable from "components/table/refreshTable";
import { Skeleton } from "@material-ui/lab";

const style = (theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#ebedeec2",
    overflowY: "auto",
    padding: "16px",
    maxHeight: "70vh",
  },
  footer: {},
  orderButton: {
    textTransform: "none",
    padding: "4px 8px",
    backgroundColor: "#ffffff",
  },
});

const useStyles = makeStyles(style);

export default function LiveDrawSetupDialogComponent({
  isOpen,
  handleClose,
  prizes,
  setPrizes,
  participantCount,
  setParticipantCount,
  handleSubmit,
  lang,
  isSubmitting,
  isError,
  isFetching,
  handleReload,
  handleChangeOrder,
  open,
  anchorEl,
  handleOrderClick,
  handleOrderClose,
  handlePrizesUpdate,
}) {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {
        setPrizes(null);
        setParticipantCount(0);
        handleClose();
      }}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.LIVE_DRAW_SETUP")}</b>
        </Typography>
        <IconButton
          onClick={() => {
            setPrizes(null);
            setParticipantCount(0);
            handleClose();
          }}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>

      <Divider />

      {isFetching && (
        <Box p={2}>
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
          <Skeleton variant="text" animation="wave" height={25} />
        </Box>
      )}

      {participantCount && prizes && <Formik
        initialValues={{ prizes }}
        enableReinitialize
        validateOnMount
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          prizes: Yup.array().of(
            Yup.object({
              draw_type: Yup.number().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              ),
              draw_duration: Yup.number()
                .moreThan(
                  0,
                  getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                    value: 0,
                  })
                )
                .max(
                  120,
                  getLang(lang, "message.error.MAX_DRAW_DURATION", {
                    total: 120,
                  })
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
            })
          ).test(
            "prize-more-than-zero",
            getLang(lang, "message.error.MUST_HAVE_AT_LEAST_ONE_PRIZE"),
            (prizes) => {
              const sum = prizes?.reduce(
                (acc, prize) => acc + (prize.is_enabled ? Number(prize.allocation_qty) : 0),
                0
              ) ?? 0;
              return sum > 0;
            }
          ).test(
            "prize-less-or-equal-than-participant",
            getLang(lang, "message.error.MUST_BE_LESS_OR_EQUAL_THAN_PARTICIPANT"),
            (prizes) => {
              const sum = prizes?.reduce(
                (acc, prize) => acc + (prize.is_enabled ? Number(prize.allocation_qty) : 0),
                0
              ) ?? 0;
              return sum <= participantCount;
            }
          ),
        })}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.container} p={2}>
              <Box>
                <Box
                  mb={2}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="body2">
                    {getLang(lang, "paragraph.LIVE_DRAW_SETUP_DESC")}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      id="order-button"
                      aria-controls={open ? "order-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleOrderClick}
                      variant="outlined"
                      className={classes.orderButton}
                      disabled={isFetching || isError}
                    >
                      {getLang(lang, "label.ORDER_BY")}
                    </Button>
                    <Menu
                      id="order-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleOrderClose}
                      MenuListProps={{
                        "aria-labelledby": "order-button",
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          handleChangeOrder("Asc", formik.values.prizes)
                        }
                      >
                        {`${getLang(lang, "label.PRIZE_TIER")} 
                        (${getLang(lang, "label.ASCENDING")})`}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleChangeOrder("Desc", formik.values.prizes)
                        }
                      >
                        {`${getLang(lang, "label.PRIZE_TIER")} 
                        (${getLang(lang, "label.DESCENDING")})`}
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
                {isFetching ? (
                  <Box>
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                  </Box>
                ) : isError ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RefreshTable handleReload={handleReload} />
                  </Box>
                ) : (
                  <DndProvider backend={HTML5Backend}>
                    {formik.values.prizes.map((prize, index) => (
                      <DraggablePrizeCard
                        key={prize.id}
                        index={index}
                        lang={lang}
                        updatePrize={(val) => {
                          formik.setFieldValue("prizes", val);
                          handlePrizesUpdate(val);
                          // if (!formik.values.isUpdated) {
                          //   formik.setFieldValue("isUpdated", true);
                          // }
                        }}
                        prize={prize}
                        prizes={formik.values.prizes}
                        isSubmitting={isSubmitting}
                        formik={formik}
                      />
                    ))}
                  </DndProvider>
                )}
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              p={2}
            >
              <Box style={{ flex: 1 }}>
                <Typography variant="body2">
                  {getLang(lang, "label.TOTAL_PARTICIPANT")}: {participantCount}
                </Typography>
                <Typography variant="body2">
                  {getLang(lang, "label.TOTAL_PRIZE_AVAILABLE")}: {prizes.reduce((acc, prize) => acc + Number(prize.quantity), 0)}
                </Typography>
                <Typography variant="body2">
                  {getLang(lang, "label.TOTAL_PRIZE_ALLOCATED_FOR_DRAW")}: {formik.values.prizes.reduce((acc, prize) => acc + (prize.is_enabled ? Number(prize.allocation_qty) : 0), 0)}
                </Typography>
                <Typography variant="body2" style={{ color: '#f44336' }}>
                  {formik.errors.prizes}
                </Typography>
              </Box>
              <Button
                variant="text"
                disabled={isSubmitting}
                disableElevation
                onClick={() => {
                  formik.resetForm();
                  handleClose();
                }}
                style={{ marginRight: "4px" }}
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
              <Button
                disabled={isFetching || isSubmitting || !formik.isValid}
                variant="contained"
                color="secondary"
                type="submit"
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      style={{ width: 15, height: 15, color: "white" }}
                    />
                  )
                }
              >
                {getLang(lang, "label.START_NOW")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>}
    </Dialog>
  );
}
