import axios from "axios";

const endpoint_url = "/campaignV2/customer/options/presetTRParticipants";

const getPresetTRParticipants = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          uuid: params.uuid,
          search: params.search,
          orderBy: params.orderBy,
          limit: params.limit,
          skip: params.skip,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getPresetTRParticipants;
