import React from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import parse from "html-react-parser";
import {
  AttachFile as AttachFileIcon,
  CheckRounded as CheckRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
} from "@material-ui/icons";
import moment from "moment";
import { currencies } from "lib/constants/currency";
import {
  ldState,
  participantFieldTypeChoice,
} from "modules/lucky-draw-v2/utils/constants";
import { Skeleton } from "@material-ui/lab";
import ThankYouImage from "assets/img/thank_you.png";
import LivePreviewPanel from "../../panel/livePreviewPanel";
import { ErrorRounded as ErrorRoundedIcon } from "@material-ui/icons";
import { TYPE_TRADITIONAL_RAFFLE } from "modules/lucky-draw-v2/constants";

const useStyles = makeStyles({
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  itemContainer: {
    background: "#F8F8F8",
    borderRadius: "4px",
    padding: "10px",
  },
  greyText: {
    color: "#A0A0A0",
  },
  prizeCard: {
    flex: 1,
    display: "flex",
    gap: "20px",
    borderRadius: "4px",
    padding: "10px",
    background: "#F8F8F8",
    alignItems: "center",
  },
  greyCard: {
    background: "#F8F8F8",
    borderRadius: 4,
    padding: 16,
    boxShadow: "0px 2px 2px 0px #00000026",
  },
  timelineContainer: {
    width: "90%",
    height: 2,
    backgroundColor: "black",
    position: "relative",
  },
  timelineConnector: {
    height: 2,
    backgroundColor: "transparent",
    flexGrow: 1,
    borderBottom: "2px dotted #D0D5DD",
  },
  timelineItem: {
    position: "absolute",
    top: -10,
    display: "flex",
    justifyContent: "center",
  },
  timelineDot: {
    height: 22,
    display: "flex",
    alignItems: "center",
    "& div": {
      height: 10,
      width: 10,
      borderRadius: "50%",
      backgroundColor: "#98A2B3",
    },
  },
  timelineContent: {
    position: "absolute",
    bottom: 37,
    backgroundColor: "#3A4D54",
    borderRadius: 8,
    padding: "4px 12px",
  },
});

export default function PreviewFormComponent({
  uuid,
  initialValues,
  timezone,
  setCurrentStep,
  lang,
  handleSubmitForm,
  isSubmitting,
  handlePrevious,
  type,
  disbursementInfos,
  journey,
  isLoading,
  ldCurrentState,
}) {
  const classes = useStyles();
  const prizeTitles = [
    getLang(lang, "label.1ST_PRIZE"),
    getLang(lang, "label.2ND_PRIZE"),
    getLang(lang, "label.3RD_PRIZE"),
    getLang(lang, "label.4TH_PRIZE"),
    getLang(lang, "label.5TH_PRIZE"),
    getLang(lang, "label.UNRANKED_PRIZE"),
  ];
  const prizes = initialValues?.prizeInfo ?? [];
  const participantFormFields = initialValues?.participantInfo.formFields ?? [];
  const prizeCount =
    type === 1
      ? initialValues?.prizeInfo.filter((i) => !!i.length).length
      : initialValues?.prizeInfo.length;

  const getJoinEndAction = (action) => {
    switch (Number(action)) {
      case 0:
        return getLang(lang, "label.NO_ACTION_NEEDED");
      case 1:
        return getLang(lang, "label.START_COUNTDOWN_TIMER");
      case 2:
        return getLang(lang, "label.SHOWS_REDIRECT_BUTTON");
      default:
        return getLang(lang, "label.NO_ACTION_NEEDED");
    }
  };

  let defaultPrize = type === 2 ? prizes.find((p) => !!p.isDefault) : null;
  let isEnded = ldCurrentState && ldCurrentState === ldState.ended;
  let isDrawing = ldCurrentState && ldCurrentState === ldState.drawStart;

  return (
    <Box mt={3} style={{ display: "flex", gap: "20px" }}>
      <Box flex={1} width="55%">
        <Paper style={{ padding: "20px" }} elevation={0}>
          <Box
            mb={2.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <b>{getLang(lang, "label.BASIC_SETTING")}</b>
            </Typography>
            {!isDrawing && !isEnded && !isDrawing && (
              <Typography
                variant="body2"
                style={{ color: "#3E7FCB", cursor: "pointer" }}
                onClick={() => {
                  const params = new URLSearchParams(window.location.search);
                  params.set("step", 1);
                  window.history.replaceState(null, null, `?${params}`);
                  setCurrentStep(0);
                }}
              >
                <b>{getLang(lang, "label.EDIT")}</b>
              </Typography>
            )}
          </Box>

          {isLoading ? (
            <>
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
            </>
          ) : (
            <Box
              mt={3}
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {initialValues?.basicInfo.name}
              </Typography>
              <Typography variant="body2">
                {initialValues?.basicInfo.description}
              </Typography>
              <Box mt={2}>
                <Typography variant="body2" className={classes.fieldLabel}>
                  {getLang(
                    lang,
                    type === 2
                      ? "label.INSTANT_WIN_IMAGE"
                      : "label.LUCKY_DRAW_IMAGE"
                  )}
                </Typography>
                <Box>
                  {initialValues?.basicInfo.image ? (
                    <img
                      src={initialValues?.basicInfo.image}
                      alt="lucky draw banner"
                      style={{
                        borderRadius: "4px",
                        maxWidth: "100%",
                        objectFit: "contain",
                        maxHeight: "200px",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <Box display="flex" alignItems="center" mt={0.5}>
                      <Box mr={1}>
                        <CheckRoundedIcon color="secondary" />
                      </Box>
                      <Typography variant="body2">
                        {getLang(lang, "label.USE_DEFAULT_TEMPLATE")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {initialValues?.basicInfo.rules ? (
                <Box mt={2}>
                  <Typography variant="body2" className={classes.fieldLabel}>
                    {getLang(lang, "label.GAME_RULES")}
                  </Typography>
                  <Box className={classes.itemContainer} p={2}>
                    {initialValues?.basicInfo.gameRulesType === "text" ? (
                      parse(initialValues?.basicInfo.rules ?? "")
                    ) : (
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <AttachFileIcon
                          style={{ color: "#A0A0A0", fontSize: 20 }}
                        />
                        <a
                          href={initialValues?.basicInfo.rules}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginBottom: "4px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {initialValues?.basicInfo.gameRulesType === "link"
                            ? initialValues?.basicInfo.rules
                            : new URL(
                                initialValues?.basicInfo.rules ??
                                  "http://example.com"
                              ).pathname
                                .split("/")
                                .pop() || getLang(lang, "label.VIEW")}
                        </a>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : null}

              <Box mt={2}>
                <Typography variant="body2" className={classes.fieldLabel}>
                  {getLang(lang, "label.TERMS_AND_CONDITIONS")}
                </Typography>
                <Box className={classes.itemContainer} p={2}>
                  {initialValues?.basicInfo.tncType === "text" ? (
                    parse(initialValues?.basicInfo.tnc ?? "")
                  ) : (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <AttachFileIcon
                        style={{ color: "#A0A0A0", fontSize: 20 }}
                      />
                      <a
                        href={initialValues?.basicInfo.tnc}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          marginBottom: "4px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {initialValues?.basicInfo.tncType === "link"
                          ? initialValues?.basicInfo.tnc
                          : new URL(
                              initialValues?.basicInfo.tnc ??
                                "http://example.com"
                            ).pathname
                              .split("/")
                              .pop() || getLang(lang, "label.VIEW")}
                      </a>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Paper>

        <Paper style={{ padding: "20px", marginTop: "20px" }} elevation={0}>
          <Box
            mb={2.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <b>{getLang(lang, "label.PHASE_SETTING")}</b>
            </Typography>
            {!isEnded &&
              (!isDrawing ||
                Number(initialValues?.periodInfo.joinEndAction) === 2) && (
                <Typography
                  variant="body2"
                  style={{ color: "#3E7FCB", cursor: "pointer" }}
                  onClick={() => {
                    const params = new URLSearchParams(window.location.search);
                    params.set("step", 2);
                    window.history.replaceState(null, null, `?${params}`);
                    setCurrentStep(1);
                  }}
                >
                  <b>{getLang(lang, "label.EDIT")}</b>
                </Typography>
              )}
          </Box>
          {isLoading ? (
            <>
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
            </>
          ) : (
            <>
              <Box>
                <Typography
                  variant="body2"
                  className={classes.fieldLabel}
                  gutterBottom
                >
                  {getLang(lang, "label.PREPARATION_PHASE")}
                </Typography>
                <Box className={classes.itemContainer} p={2}>
                  {Boolean(initialValues?.periodInfo.enablePreview) ? (
                    <>
                      <Box mb={1}>
                        <Typography variant="body2">
                          <span
                            className={classes.greyText}
                            style={{ fontSize: 13 }}
                          >
                            {getLang(lang, "label.START_AT")}
                          </span>{" "}
                          <span style={{ margin: "0px 8px" }}>
                            {moment(
                              initialValues?.periodInfo.preStartDate
                            ).format("DD/MM/YYYY")}
                          </span>{" "}
                          •{" "}
                          <span style={{ margin: "0px 8px" }}>
                            {initialValues?.periodInfo.preStartTime}
                          </span>{" "}
                          <span style={{ fontSize: 13 }}>GMT{timezone}</span>
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        <span className={classes.greyText}>
                          {getLang(lang, "label.DISPLAY_BANNER_DURING_PREP")}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2" className={classes.greyText}>
                      {getLang(lang, "label.NONE")}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box mt={2}>
                <Typography
                  variant="body2"
                  className={classes.fieldLabel}
                  gutterBottom
                >
                  {getLang(lang, "label.OPEN_PHASE")}
                </Typography>
                <Box className={classes.itemContainer} p={2}>
                  <Box>
                    <Typography variant="body2">
                      <span
                        className={classes.greyText}
                        style={{ fontSize: 13 }}
                      >
                        {getLang(lang, "label.START_AT")}
                      </span>{" "}
                      <span style={{ margin: "0px 8px" }}>
                        {moment(initialValues?.periodInfo.joinOpenDate).format(
                          "DD/MM/YYYY"
                        )}
                      </span>{" "}
                      •{" "}
                      <span style={{ margin: "0px 8px" }}>
                        {initialValues?.periodInfo.joinOpenTime}
                      </span>{" "}
                      <span style={{ fontSize: 13 }}>GMT{timezone}</span>
                    </Typography>
                  </Box>
                  {initialValues?.periodInfo.joinCloseDate && (
                    <Box mt={1}>
                      <Typography variant="body2">
                        <span
                          className={classes.greyText}
                          style={{ fontSize: 13 }}
                        >
                          {getLang(lang, "label.END_AT")}
                        </span>{" "}
                        <span style={{ margin: "0px 8px" }}>
                          {moment(
                            initialValues?.periodInfo.joinCloseDate
                          ).format("DD/MM/YYYY")}
                        </span>{" "}
                        •{" "}
                        <span style={{ margin: "0px 8px" }}>
                          {initialValues?.periodInfo.joinCloseTime}
                        </span>{" "}
                        <span style={{ fontSize: 13 }}>GMT{timezone}</span>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {type === 1 && (
                <Box mt={2}>
                  <Box className={classes.itemContainer} p={2}>
                    <Box>
                      <Typography variant="body2">
                        <span
                          className={classes.greyText}
                          style={{ fontSize: 13, marginRight: 8 }}
                        >
                          {getLang(
                            lang,
                            type === 2
                              ? "label.WHEN_INSTANT_WIN_CLOSED"
                              : "label.WHEN_LUCKY_DRAW_CLOSED"
                          )}
                        </span>{" "}
                        {getJoinEndAction(
                          initialValues?.periodInfo.joinEndAction
                        )}
                      </Typography>
                    </Box>
                    {Number(initialValues?.periodInfo.joinEndAction) === 1 && (
                      <Box mt={1}>
                        <Typography variant="body2">
                          <span
                            className={classes.greyText}
                            style={{ fontSize: 13 }}
                          >
                            {getLang(lang, "label.COUNTDOWN_ENDS_AT")}
                          </span>{" "}
                          <span style={{ margin: "0px 8px" }}>
                            {moment(initialValues?.periodInfo.drawDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>{" "}
                          •{" "}
                          <span style={{ margin: "0px 8px" }}>
                            {initialValues?.periodInfo.drawTime}
                          </span>{" "}
                          <span style={{ fontSize: 13 }}>GMT{timezone}</span>
                        </Typography>
                      </Box>
                    )}
                    {Number(initialValues?.periodInfo.joinEndAction) === 2 && (
                      <Box mt={1}>
                        <Typography
                          variant="body2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span
                            className={classes.greyText}
                            style={{ marginRight: 8 }}
                          >
                            {getLang(lang, "label.EXTERNAL_SITE")}
                          </span>{" "}
                          {initialValues?.periodInfo.externalLink}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Paper>

        <Paper style={{ padding: "20px", marginTop: "20px" }} elevation={0}>
          <Box
            mb={2.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <b>{getLang(lang, "label.PRIZE_SETTING")}</b>
            </Typography>
            {!isEnded && !isDrawing && (
              <Box display="flex" style={{ gap: 28 }}>
                <Typography
                  variant="body2"
                  style={{ color: "#3E7FCB", cursor: "pointer" }}
                  onClick={() => {
                    const params = new URLSearchParams(window.location.search);
                    params.set("step", 3);
                    window.history.replaceState(null, null, `?${params}`);
                    setCurrentStep(2);
                  }}
                >
                  <b>
                    {getLang(
                      lang,
                      type === 2 ? "label.EDIT_PRIZE" : "label.EDIT"
                    )}
                  </b>
                </Typography>
                {type === 2 && (
                  <Typography
                    variant="body2"
                    style={{
                      color: "#3E7FCB",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      params.set("step", 4);
                      window.history.replaceState(null, null, `?${params}`);
                      setCurrentStep(3);
                    }}
                  >
                    <b>{getLang(lang, "label.EDIT_WIN_RATE")}</b>
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          {isLoading ? (
            <>
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
            </>
          ) : (
            <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
              {prizeCount ? (
                type === 2 ? (
                  <Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ gap: "10px" }}
                    >
                      {prizes
                        .filter((p) => !p.isDefault)
                        .map((prize, index) => {
                          let disbursement = null;
                          if (
                            prize.prizeType === 2 &&
                            prize.disbursementCompanyId
                          ) {
                            disbursement = disbursementInfos.find(
                              (d) => d.id === prize.disbursementCompanyId
                            );
                          }
                          return (
                            <Box key={index} className={classes.prizeCard}>
                              <Box>
                                <img
                                  src={
                                    typeof prize.imgUrl === "string"
                                      ? prize.imgUrl
                                      : URL.createObjectURL(prize.imgUrl)
                                  }
                                  alt="prize"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                    display: "block",
                                  }}
                                />
                              </Box>
                              <Typography style={{ flex: 1, fontSize: "14px" }}>
                                {prize.prizeType === 2 ? (
                                  <>
                                    {prize.title}
                                    <span style={{ margin: "0px 4px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    {prize.unlimitedQty
                                      ? getLang(lang, "label.UNLIMITED")
                                      : `${prize.quantity} pc(s)`}
                                    <span style={{ margin: "0px 4px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    {disbursement
                                      ? disbursement.provider_name || ""
                                      : getLang(lang, "label.CREDIT_VALUE")}
                                    <span style={{ margin: "0px 4px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    {`${
                                      disbursement?.currency || ""
                                    } ${prize.creditAmount.toFixed(
                                      disbursement?.currency
                                        ? currencies.find(
                                            (c) =>
                                              c.alpha3 === disbursement.currency
                                          )?.exp || 2
                                        : 2
                                    )}`}
                                  </>
                                ) : (
                                  <>
                                    {prize.title}
                                    <span style={{ margin: "0px 4px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    {prize.unlimitedQty
                                      ? getLang(lang, "label.UNLIMITED")
                                      : `${prize.quantity} pc(s)`}
                                    <span style={{ margin: "0px 4px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    {prize.prizeType === 1
                                      ? getLang(lang, "label.ITEM")
                                      : getLang(lang, "label.REDEMPTION_CODE")}
                                  </>
                                )}
                                {!!Number(
                                  initialValues?.winRateInfo?.hasWinRate
                                ) && (
                                  <>
                                    <span style={{ margin: "0px 8px" }}>
                                      {" "}
                                      •{" "}
                                    </span>
                                    <span>{prize.winRate.toFixed(3)}%</span>
                                  </>
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                    {defaultPrize && (
                      <Box mt={2}>
                        <Typography variant="body2" gutterBottom>
                          <b>{getLang(lang, "label.LOSS_STATE")}</b>
                        </Typography>
                        <Box className={classes.prizeCard}>
                          <Box>
                            <img
                              src={
                                !defaultPrize.imgUrl
                                  ? ThankYouImage
                                  : typeof defaultPrize.imgUrl === "string"
                                  ? defaultPrize.imgUrl
                                  : URL.createObjectURL(defaultPrize.imgUrl)
                              }
                              alt="prize"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "4px",
                                display: "block",
                              }}
                            />
                          </Box>
                          <Typography style={{ flex: 1, fontSize: "14px" }}>
                            {defaultPrize.title}
                            <span style={{ margin: "0px 4px" }}> • </span>
                            {defaultPrize.description}
                            {!!Number(
                              initialValues?.winRateInfo?.hasWinRate
                            ) && (
                              <>
                                <span style={{ margin: "0px 4px" }}> • </span>
                                <span>{defaultPrize.winRate.toFixed(3)}%</span>
                              </>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  prizes.map(
                    (prize, index) =>
                      !!prize.length && (
                        <Box key={index}>
                          <Typography style={{ fontWeight: 600 }} gutterBottom>
                            {prizeTitles[index]}
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            style={{
                              gap: "10px",
                            }}
                          >
                            {prize.map((item, itemIndex) => (
                              <Box
                                key={itemIndex}
                                className={classes.prizeCard}
                              >
                                <Box>
                                  <img
                                    src={
                                      typeof item.imgUrl === "string"
                                        ? item.imgUrl
                                        : URL.createObjectURL(item.imgUrl)
                                    }
                                    alt="prize"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "4px",
                                      display: "block",
                                    }}
                                  />
                                </Box>
                                <Typography
                                  style={{ flex: 1, fontSize: "14px" }}
                                >
                                  {[
                                    item.title,
                                    `${item.quantity} pc(s)`,
                                    item.prizeType === 1
                                      ? getLang(lang, "label.ITEM")
                                      : getLang(lang, "label.REDEMPTION_CODE"),
                                  ].join(" • ")}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )
                  )
                )
              ) : (
                <Box
                  className={classes.itemContainer}
                  p={2}
                  display="flex"
                  alignItems="center"
                >
                  <ErrorRoundedIcon style={{ marginRight: 8 }} />
                  <Typography variant="body2">
                    {getLang(lang, "paragraph.NO_PRIZE_SET")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Paper>

        <Paper style={{ padding: "20px", marginTop: "20px" }} elevation={0}>
          <Box
            mb={2.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <b>{getLang(lang, "label.PARTICIPANT_SETTING")}</b>
            </Typography>
            {!isEnded && !isDrawing && (
              <Typography
                variant="body2"
                style={{ color: "#3E7FCB", cursor: "pointer" }}
                onClick={() => {
                  const params = new URLSearchParams(window.location.search);
                  params.set("step", type === 2 ? 5 : 4);
                  window.history.replaceState(null, null, `?${params}`);
                  setCurrentStep(type === 2 ? 4 : 3);
                }}
              >
                <b>{getLang(lang, "label.EDIT")}</b>
              </Typography>
            )}
          </Box>
          {isLoading ? (
            <>
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
            </>
          ) : (
            <>
              <Box className={classes.greyCard} mb={2}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pt={3}
                  pb={2}
                  px={2}
                  height={136}
                  bgcolor="white"
                >
                  <Box className={classes.timelineContainer}>
                    <Box position="absolute" right={-10} top={-11}>
                      <ChevronRightRoundedIcon style={{ color: "black" }} />
                    </Box>
                    {journey.map((item, index) => (
                      <Box
                        key={index}
                        className={classes.timelineItem}
                        style={{
                          left: `${(index / (journey.length - 1)) * 90}%`,
                        }}
                      >
                        {["login", "submit-form"].includes(item.identifier) && (
                          <Box className={classes.timelineContent}>
                            <Typography
                              variant="body2"
                              style={{ whiteSpace: "nowrap", color: "white" }}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                        )}
                        {["login", "submit-form"].includes(item.identifier) ? (
                          <CheckCircleRoundedIcon
                            color="secondary"
                            style={{
                              fontSize: 22,
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <Box className={classes.timelineDot}>
                            <Box />
                          </Box>
                        )}
                        {!["login", "submit-form"].includes(
                          item.identifier
                        ) && (
                          <Box position="absolute" top={30}>
                            <Typography
                              variant="body2"
                              style={{ whiteSpace: "nowrap", color: "#98A2B3" }}
                            >
                              {item.title?.toUpperCase()}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  className={classes.fieldLabel}
                  gutterBottom
                >
                  {getLang(lang, "label.ALLOW_PREV_SN_JOIN")}
                </Typography>

                <Box className={classes.greyCard} mb={2}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {initialValues.participantInfo.allowPrevSnJoin
                        ? getLang(lang, "label.YES_ALLOW")
                        : getLang(lang, "label.NO_NOT_ALLOW")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {  type === TYPE_TRADITIONAL_RAFFLE && (
              <Box>
                <Typography
                  variant="body2"
                  className={classes.fieldLabel}
                  gutterBottom
                >
                  {getLang(lang, "label.DISABLE_PREV_WINNER_WIN")}
                </Typography>

                <Box className={classes.greyCard} mb={2}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {initialValues.participantInfo.disablePrevWinnerWin
                        ? getLang(lang, "label.YES")
                        : getLang(lang, "label.NO")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              )}
              {!!initialValues.participantInfo.submitFormRequired && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {participantFormFields.map((field, index) => (
                    <Box
                      className={classes.itemContainer}
                      p={1}
                      key={index}
                      display="flex"
                    >
                      <Box width={88} className={classes.greyText} pt={0.25}>
                        <Typography
                          variant="body2"
                          className={classes.greyText}
                        >
                          {getLang(lang, "label.QUESTION")} {index + 1}:
                        </Typography>
                      </Box>
                      <Box>
                        <Box display="flex" alignItems="center" minHeight={25}>
                          <Typography variant="body2">
                            {field.variantName}{" "}
                            <span style={{ margin: "0px 8px" }}>•</span>{" "}
                            {getLang(
                              lang,
                              participantFieldTypeChoice.find(
                                (f) => f.value === field.type
                              )?.labelType || "label.TEXT_FIELD"
                            )}{" "}
                            <span style={{ margin: "0px 8px" }}>•</span>{" "}
                          </Typography>
                          {field.mandatory && (
                            <Chip
                              label={getLang(lang, "label.MANDATORY")}
                              style={{
                                height: "25px",
                                backgroundColor: "#FFF2DE",
                                color: "#E3AA17",
                                borderRadius: "4px",
                              }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="caption"
                          className={classes.greyText}
                        >
                          {getLang(lang, "label.TOOLTIP")}:{" "}
                          {field.tooltip ?? "-"}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </>
          )}
        </Paper>

        <Box display="flex" alignItems="center" mt={2.5}>
          <Box mr={1}>
            <Button
              variant="outlined"
              onClick={() => handlePrevious()}
              disabled={isSubmitting}
            >
              {getLang(lang, "label.PREVIOUS")}
            </Button>
          </Box>
          <Button
            variant="contained"
            style={{ color: "white", backgroundColor: "#6AAF68" }}
            type="submit"
            disabled={isSubmitting}
            startIcon={
              isSubmitting && (
                <CircularProgress
                  style={{ width: 15, height: 15, color: "white" }}
                />
              )
            }
            onClick={() => handleSubmitForm()}
          >
            {getLang(lang, "label.CONFIRM")}
          </Button>
        </Box>
      </Box>
      <LivePreviewPanel luckyDraw={initialValues} lang={lang} />
      {/* <LivePreview initialValues={initialValues} lang={lang} /> */}
    </Box>
  );
}
