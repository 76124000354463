/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useContext } from "react";
import SetTranditionalRaffleWinnerSelect from "./setTranditionalRaffleWinnerSelect.component";
import SetTranditionalRaffleWinnerPanel from "./setTranditionalRaffleWinnerPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import { PrizeContext } from "../../dialog/tranditionalRaffleWinnerDialog/tranditionalRaffleWinnerDialog.container";
import luckyDrawV2Api from "app/api/luckyDrawV2";

function SetTranditionalRaffleWinnerSelectContainer({
  prizeIndex,
  value,
  winnerIndex,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const { luckyDrawUuid, handleSetWinner, excludeList } =
    useContext(PrizeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
  });

  const isFetchingRef = useRef(false);
  const participantsRef = useRef([]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleFetchParticipant();
  }, [filter]);

  const handleSearch = (value) => {
    setParticipants([]);
    setTotalRecords(0);
    participantsRef.current = [];
    setFilter({
      ...filter,
      page: 0,
      search: value,
    });
  };

  const handleFetchParticipant = async () => {
    if (!isFetchingRef.current) {
      if (isError) setIsError(false);
      setIsFetching(true);
      isFetchingRef.current = true;
      await luckyDrawV2Api
        .getPresetTRParticipants({
          uuid: luckyDrawUuid,
          search: filter.search,
          limit: filter.rowsPerPage,
          skip: filter.rowsPerPage * filter.page,
        })
        .then((response) => {
          const { data, records_filtered } = response.data.data;
          setTotalRecords(records_filtered);
          participantsRef.current = data;
          if (filter.page === 0) {
            participantsRef.current = data;
          } else {
            participantsRef.current = [...participantsRef.current, ...data];
          }
          setParticipants(participantsRef.current);
        })
        .catch((error) => {
          setIsError(true);
        })
        .finally(() => {
          setIsFetching(false);
          isFetchingRef.current = false;
        });
    }
  };

  const handleSelectWinner = (serialNumber, enc) => {
    handleSetWinner(prizeIndex, winnerIndex, serialNumber, enc);
    handleClose();
  };

  const handleFetchMoreItems = () => {
    if (participants.length < totalRecords) {
      setFilter({
        ...filter,
        page: filter.page + 1,
      });
    }
  };

  const handleReload = () => {
    setParticipants([]);
    setTotalRecords(0);
    participantsRef.current = [];
    if (isError) setIsError(false);

    setFilter({
      page: 0,
      rowsPerPage: 25,
      search: "",
    });
  };

  return (
    <>
      <SetTranditionalRaffleWinnerSelect
        handleClick={handleOpen}
        value={value}
        placeholder={getLang(lang, "placeholder.SELECT_WINNER")}
      />
      <SetTranditionalRaffleWinnerPanel
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleListKeyDown={handleSelectWinner}
        isFetching={isFetching}
        value={value}
        participants={participants}
        excludeList={excludeList}
        fetchMoreData={handleFetchMoreItems}
        handleSearch={handleSearch}
        lang={lang}
        totalRecords={totalRecords}
        isError={isError}
        handleReload={handleReload}
      />
    </>
  );
}

SetTranditionalRaffleWinnerSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default SetTranditionalRaffleWinnerSelectContainer;
