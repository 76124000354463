import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  EmailOutlined as EmailIcon,
  PhoneOutlined as PhoneIcon,
  CakeOutlined as CakeIcon,
  Business as BusinessIcon,
  AccountBox as AccountBoxIcon,
} from "@material-ui/icons";
import GenderIcon from "assets/img/gender.png";
import moment from "moment";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import { industries, roles } from "modules/dashboard/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    marginTop: 2,
    padding: "16px 20px",
  },
  infoSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2),
    },
  },
  icon: {
    color: "#98A2B3",
    marginRight: 12,
    fontSize: 24,
  },
  gender: {
    width: 25,
    height: 24,
    objectFit: "contain",
    marginRight: 12,
  },
  addressBox: {
    border: "2px solid #D0D5DD",
    padding: "5px 10px",
  },
}));

export default function ProfilePanelComponent({
  consumer,
  lang,
  language,
  companyId,
}) {
  const classes = useStyles();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const ageString = moment(consumer.date_of_birth).startOf("day").fromNow();
  const ageNumber = consumer.date_of_birth
    ? parseInt(ageString.match(/\d+/)[0])
    : null;

  return (
    <>
      <Box className={classes.container}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.ABOUT")}</b>
        </Typography>
        <Box className={classes.infoSection}>
          <Box>
            <Box display="flex" alignItems="center" mt={1.5}>
              <EmailIcon className={classes.icon} />
              <Typography>{consumer.email || "-"}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1.5}>
              <PhoneIcon className={classes.icon} />
              <Typography>
                {!!consumer.phone_code && <span>{consumer.phone_code} </span>}
                {consumer.phone_number || "-"}
              </Typography>
            </Box>
            {process.env.REACT_APP_ENV === "PRODUCTION" &&
              companyId === 183 && (
                <>
                  <Box display="flex" alignItems="center" mt={1.5}>
                    <AccountBoxIcon className={classes.icon} />
                    <Typography>
                      {consumer.role
                        ? consumer.role === 12
                          ? consumer.role_specify
                          : getLang(lang, roles[consumer.role - 1]?.name)
                        : "-"}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1.5}>
                    <BusinessIcon className={classes.icon} />
                    <Typography>
                      {consumer.industry
                        ? consumer.industry === 10
                          ? consumer.industry_specify
                          : getLang(
                              lang,
                              industries[consumer.industry - 1]?.name
                            )
                        : "-"}
                    </Typography>
                  </Box>
                </>
              )}
          </Box>
          <Box>
            <Box display="flex" alignItems="center" mt={1.5}>
              <CakeIcon className={classes.icon} />
              <Typography>
                {consumer.date_of_birth ? (
                  <>
                    {moment(consumer.date_of_birth).format("ll")}{" "}
                    <Typography color="textSecondary" display="inline">
                      ({getLang(lang, "label.AGE")} {ageNumber ?? "-"})
                    </Typography>
                  </>
                ) : (
                  "-"
                )}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1.5}>
              <img src={GenderIcon} alt="gender" className={classes.gender} />
              <Typography>
                {consumer.gender
                  ? consumer.gender === "Male"
                    ? getLang(lang, "label.MALE")
                    : getLang(lang, "label.FEMALE")
                  : "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.ADDRESS")}</b>
        </Typography>
        <Box className={classes.infoSection} mt={1.5}>
          {consumer.addresses?.length ? (
            consumer.addresses?.map((address) => (
              <Box
                key={address.id}
                className={classes.addressBox}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  {!!address.is_primary && (
                    <Typography variant="body1" style={{ color: "#6AAF68" }}>
                      {getLang(lang, "label.DEFAULT_ADDRESS")}
                    </Typography>
                  )}
                  <Typography variant="body1">{address.name}</Typography>
                  <Typography variant="body1">
                    {address.phone_code ? (
                      <span>{address.phone_code} </span>
                    ) : (
                      ""
                    )}
                    {address.contact_number}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ marginTop: 16 }}>
                    {address.address}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" style={{ margin: "8px 0px 16px" }}>
              {getLang(lang, "paragraph.NO_ADDRESS_PROVIDED")}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
