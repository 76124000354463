/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import LiveDrawSetupDialogComponent from "./liveDrawSetupDialog.component";
import { useDispatch, useSelector } from "react-redux";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/lucky-draw-v2/config/lucky-draw-v2.route";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";

function LiveDrawSetupDialogContainer({ isOpen, handleClose, uuid }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);

  const [prizes, setPrizes] = useState();
  const [participantCount, setParticipantCount] = useState(0);
  const [isFetching, setIsFetching] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const prizesRef = useRef([]);

  useEffect(() => {
    if (uuid) {
      getData();
    }
  }, [uuid]);

  const getData = () => {
    if (isError) setIsError(false);
    setIsFetching(true);
    luckyDrawV2Api
      .getLuckyDrawDetails(uuid)
      .then((res) => {
        //sort desc by default
        const sortedPrizes = sortPrizes("Desc", res.data.data.prizes);
        setPrizes(sortedPrizes.map(prize => ({
          ...prize,
          draw_type: Number(prize.draw_type ?? 1),
          draw_duration: Number(prize.draw_duration ?? 3),
          allocation_qty: Number(prize.quantity),
          is_enabled: true,
        })));
        setParticipantCount(res.data.data.lucky_draws.participants_count);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => setIsFetching(false));
  };

  const sortPrizes = (order, prizes) => {
    return prizes.sort((a, b) => {
      const rankA = parseInt(a.rank_position, 10);
      const rankB = parseInt(b.rank_position, 10);
      const sequenceA = parseInt(a.sequence, 10);
      const sequenceB = parseInt(b.sequence, 10);

      if (order === "Asc") {
        if (rankA === 0 && rankB === 0) return sequenceA - sequenceB; // If both rank_position are 0, sort by sequence
        if (rankA === 0) return 1; // Put rank_position 0 at the end
        if (rankB === 0) return -1;
        if (rankA !== rankB) return rankA - rankB; // Ascending by rank_position
        return sequenceA - sequenceB; // Ascending by sequence
      } else {
        if (rankA === 0 && rankB === 0) return sequenceB - sequenceA; // If both rank_position are 0, sort by sequence
        if (rankA === 0) return -1; // Put rank_position 0 at the beginning
        if (rankB === 0) return 1;
        if (rankA !== rankB) return rankB - rankA; // Descending by rank_position
        return sequenceB - sequenceA; // Descending by sequence
      }
    });
  };

  const handleChangeOrder = (order, currentPrizes) => {
    handleOrderClose();
    const sortedPrizes = sortPrizes(order, currentPrizes);
    setPrizes(sortedPrizes);
  };

  const handleSubmit = (values) => {
    prizesRef.current = values.prizes.map((val, i) => ({
      uuid: val.uuid,
      draw_duration: val.draw_duration,
      draw_type: val.draw_type,
      draw_sequence: i + 1,
      is_enabled: Boolean(val.is_enabled),
      allocation_qty: Number(val.allocation_qty),
    }));
    setShowConfirmDialog(true);
  };

  const updateSetup = () => {
    setShowConfirmDialog(false);
    setIsSubmitting(true);
    luckyDrawV2Api
      .updateLiveDrawSetup({
        luckyDrawUuid: uuid,
        prizes: prizesRef.current,
      })
      .then(() => {
        handleClose();
        history.push(`${ROOT}/live-draw/${uuid}`);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleOrderClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOrderClose = () => {
    setAnchorEl(null);
  };

  const handlePrizesUpdate = (prizes) => {
    setPrizes(prizes);
  };

  return (
    <>
      <LiveDrawSetupDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        lang={lang}
        prizes={prizes}
        setPrizes={setPrizes}
        participantCount={participantCount}
        setParticipantCount={setParticipantCount}
        isSubmitting={isSubmitting}
        isError={isError}
        isFetching={isFetching}
        handleReload={getData}
        handleChangeOrder={handleChangeOrder}
        open={open}
        anchorEl={anchorEl}
        handleOrderClick={handleOrderClick}
        handleOrderClose={handleOrderClose}
        handlePrizesUpdate={handlePrizesUpdate}
      />
      <GeneralDialog
        isOpen={showConfirmDialog}
        handleClose={() => {
          setShowConfirmDialog(false);
        }}
        handleProceed={() => {
          updateSetup();
        }}
        title={getLang(lang, "label.START_LIVE_DRAW")}
        description={getLang(lang, "paragraph.START_LIVE_DRAW_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.START")}
        icon={<img src={AlertIcon} alt="" />}
      />
    </>
  );
}

export default LiveDrawSetupDialogContainer;
