/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LuckyDrawDetailPage from "./luckyDrawDetail.page";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { useParams } from "react-router-dom";
import { getApiLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import {
  LUCKY_DRAW_V2_ADD_EDIT,
  LUCKY_DRAW_V2_VIEW,
} from "lib/constants/accessRights";
import moment from "moment";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { useHistory } from "react-router";

function LuckyDrawDetailContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { luckyDrawUUID } = useParams();
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_ADD_EDIT)
  );
  const hasLuckyDrawViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_V2_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const [initialValues, setInitialValues] = useState();
  const [type, setType] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!hasLuckyDrawViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasLuckyDrawViewAccessRight]);

  const transformPrizes = (prizes, type) => {
    if (type === 1) {
      const initialPrizes = [[], [], [], [], [], []];

      // Separate prizes by rank_position
      prizes.forEach((prize) => {
        initialPrizes[
          Number(prize.rank_position)
            ? prize.rank_position - 1
            : initialPrizes.length - 1
        ].push({
          ...prize,
          title: prize.name,
          imgUrl: prize.image_url,
          quantity: Number(prize.quantity),
          winQuantity: prize.win_quantity ? Number(prize.win_quantity) : 0,
          prizeType: Number(prize.type),
          sequence: Number(prize.sequence),
          disbursementCompanyId: prize.disbursement_company_id
            ? prize.disbursement_company_id
            : null,
        });
      });

      // Sort prizes by sequence
      initialPrizes.forEach((prize) => {
        prize.sort((a, b) => a.sequence - b.sequence);
      });
      return initialPrizes;
    }

    if (type === 2) {
      const initialPrizes = [];
      prizes.forEach((prize) => {
        initialPrizes.push({
          ...prize,
          title: prize.name,
          description: prize.description,
          imgUrl: prize.image_url,
          quantity: Number(prize.quantity),
          prizeType: Number(prize.type),
          sequence: Number(prize.sequence),
          creditAmount: Number(prize.credit_amount),
          disbursementCompanyId: prize.disbursement_company_id
            ? prize.disbursement_company_id
            : null,
          unlimitedQty: !!Number(prize.is_unlimited_qty),
          isDefault: Number(prize.is_default),
          winRate: prize.win_rate ? Number(prize.win_rate) : null,
          winQuantity: Number(prize.win_quantity),
          balance: Number(prize.quantity)
            ? Number(prize.quantity) - Number(prize.win_quantity || 0)
            : 0,
        });
      });

      initialPrizes.sort((a, b) => a.sequence - b.sequence);

      return initialPrizes;
    }
  };

  useEffect(() => {
    if (luckyDrawUUID) {
      getData(luckyDrawUUID);
    }
  }, [luckyDrawUUID]);

  const getData = (uuid) => {
    setIsLoading(true);
    luckyDrawV2Api
      .getLuckyDrawDetails(uuid)
      .then(async (response) => {
        const lucky_draw_data = response.data.data.lucky_draws;
        const participant_data = response.data.data.participant_forms;
        const gameRulesType = lucky_draw_data.game_rules_type;
        const tncType = lucky_draw_data.tnc_type;
        const ldType = Number(lucky_draw_data.type);

        let rules = "";
        if (gameRulesType === "text") {
          let ruleJson = await fetch(lucky_draw_data.game_rules_url).then(
            (res) => res.json()
          );
          if (ruleJson) {
            rules = ruleJson.value;
          }
        } else {
          rules = lucky_draw_data.game_rules_url;
        }

        let tnc = "";
        if (tncType === "text") {
          let tncJson = await fetch(lucky_draw_data.tnc).then((res) =>
            res.json()
          );
          if (tncJson) {
            tnc = tncJson.value;
          }
        } else {
          tnc = lucky_draw_data.tnc;
        }

        let preStart = lucky_draw_data.pre_start_at
          ? moment.utc(lucky_draw_data.pre_start_at).local()
          : null;
        let joinOpen = lucky_draw_data.join_open_at
          ? moment.utc(lucky_draw_data.join_open_at).local()
          : null;
        let joinClose = lucky_draw_data.join_close_at
          ? moment.utc(lucky_draw_data.join_close_at).local()
          : null;
        let drawAt = lucky_draw_data.draw_at
          ? moment.utc(lucky_draw_data.draw_at).local()
          : null;
        let drawStartAt = lucky_draw_data.draw_start_at
          ? moment.utc(lucky_draw_data.draw_start_at).local()
          : null;
        let endAt = lucky_draw_data.end_at
          ? moment.utc(lucky_draw_data.end_at).local()
          : null;

        let currentState = ldState.notPublished;
        if (lucky_draw_data.progress === progressStatus.published) {
          currentState = ldState.published;
        }
        if (preStart && moment().isAfter(preStart)) {
          currentState = ldState.preview;
        }
        if (joinOpen && moment().isAfter(joinOpen)) {
          currentState = ldState.joinStart;
        }
        if (joinClose && moment().isAfter(joinClose)) {
          currentState = ldState.joinEnd;
        }
        if (drawStartAt && moment().isAfter(drawStartAt)) {
          currentState = ldState.drawStart;
        }
        if (endAt && moment().isAfter(endAt)) {
          currentState = ldState.ended;
        }

        const initialValues = {
          progress: lucky_draw_data.progress,
          currentState: currentState,
          type: Number(lucky_draw_data.type),
          basicInfo: {
            name: lucky_draw_data.title,
            description: lucky_draw_data.description,
            image: lucky_draw_data.image_url,
            gameRulesType,
            rules,
            tncType,
            tnc,
          },
          periodInfo: {
            enablePreview: Boolean(lucky_draw_data.pre_start_at) ? 1 : 0,
            isAutoClose: Boolean(lucky_draw_data.join_close_at) ? 1 : 0,
            joinEndAction:
              lucky_draw_data.join_end_action !== null
                ? `${lucky_draw_data.join_end_action}`
                : null,
            preStartDate: preStart ? preStart.format("YYYY-MM-DD") : null,
            preStartTime: preStart ? preStart.format("HH:mm") : null,
            joinOpenDate: joinOpen ? joinOpen.format("YYYY-MM-DD") : null,
            joinOpenTime: joinOpen ? joinOpen.format("HH:mm") : null,
            joinCloseDate: joinClose ? joinClose.format("YYYY-MM-DD") : null,
            joinCloseTime: joinClose ? joinClose.format("HH:mm") : null,
            drawDate: drawAt ? drawAt.format("YYYY-MM-DD") : null,
            drawTime: drawAt ? drawAt.format("HH:mm") : null,
            externalLink: lucky_draw_data.external_link,
          },
          prizeInfo: transformPrizes(response.data.data.prizes, Number(ldType)),
          participantInfo: {
            signInRequired: Number(lucky_draw_data.sign_in_require),
            signInEvent: lucky_draw_data.sign_in_event,
            submitFormRequired: Number(lucky_draw_data.submit_form_require),
            submitFormEvent: lucky_draw_data.submit_form_event,
            allowPrevSnJoin: Number(lucky_draw_data.allow_prev_sn_join),
            disablePrevWinnerWin: Number(lucky_draw_data.disable_prev_winner_win),
            formFields: participant_data?.length
              ? participant_data.map((f) => ({
                  ...f,
                  fieldName: f.field_name,
                  labelType: f.label_type,
                  type: f.type,
                  mandatory: Number(f.mandatory) === 1,
                  autoFill: Number(f.auto_fill) === 1,
                  visible: Number(f.visible) === 1,
                  enableDelete: Number(f.enable_delete) === 1,
                  editing: Number(f.editing) === 1,
                  fieldType: f.field_type,
                  variantName: f.variant_name,
                  sequence: Number(f.sequence),
                  choice: f.choice
                    ? typeof f.choice === "string"
                      ? JSON.parse(f.choice)
                      : f.choice
                    : [],
                }))
              : [],
          },
          winRateInfo: {
            hasWinRate: Number(lucky_draw_data.has_win_rate),
          }
        };
        setInitialValues(initialValues);
        setType(Number(ldType));
      })
      .catch((error) => {
        dispatch(
          addAlert({ severity: "error", message: getApiLang(lang, error.code) })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LuckyDrawDetailPage
      initialValues={initialValues}
      luckyDrawUUID={luckyDrawUUID}
      type={type}
      getLuckyDrawDetail={getData}
      isLoading={isLoading}
      lang={lang}
      hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
    />
  );
}

export default LuckyDrawDetailContainer;
